<template>
  <div>
    <h3 ref="ca">
      Features of a Triangle
    </h3>
    <p>
      In this lesson, we will explore the following geometric features of a triangle: the median, the centroid and the altitude.
    </p>
    <ul class="a">
      <li>
        <h3 ref="median">
          Medians of a Triangle
        </h3>
        <p>
          A median is a line segment that connects the mid-point of a side of triangle to is opposite vertex. A triangle has three medians.
          The medians intersect at a common point.
        </p>
        <h3 ref="playgraph">
          MagicGraph 1 &vert; Medians of a Triangle
        </h3>
        <p>
          This MagicGraph offers a visually interactive approach to learn about medians of a triangle.
        </p>
        <br>
        <v-responsive>
          <v-layout justify-center>
            <div id="jxgbox1" class="edliy-box-about" />
          </v-layout>
        </v-responsive>
      </li>
      <li>
        <h3 ref="centroid">
          Centroid of a Triangle
        </h3>
        <p>
          Centroid of a triangle is the point where the three medians of the triangle intersect.
        </p>
        <br>
        <h3 ref="playgraph">
          MagicGraph 2 &vert; Centroid of a Triangle
        </h3>
        <p>
          This MagicGraph offers a visually interactive approach to learn about centroid of a triangle.
        </p>
        <v-responsive>
          <v-layout justify-center>
            <div id="jxgbox2" class="edliy-box-about" />
          </v-layout>
        </v-responsive>
      </li>
      <li>
        <h3 ref="altitude">
          Altitudes of a Triangle
        </h3>
        <p>
          An altitude of a triangle is the line segment that connects a vertex to the nearest point on the opposite side.
          A triangle can have three altitude. The length of an altitude represents the height of the triangle.
        </p>
        <h3 ref="playgraph">
          MagicGraph 3 &vert; Altitude of a Triangle
        </h3>
        <p>
          This MagicGraph offers a visually interactive approach to learn about altitudes of a triangle.
        </p>
        <v-responsive>
          <v-layout justify-center>
            <div id="jxgbox3" class="edliy-box-about" />
          </v-layout>
        </v-responsive>
      </li>
    </ul>
    <!--ref="ra">
    Special Remarks
    </h3>
  <p>  Based on the definitions of complementary and supplementary angles, following conclusions can be drawn:</p>
    <ul style="list-style-type: square;">
    <li>Two acute angles can complement to each other, but they could never be supplementary. </li>
    <li> Two obtuse angles can neither be supplementary nor complementary. </li>
    <li> Two right angles can only be supplementary. </li>
    <li> One acute angle and one obtuse angle can be the supplement of each other.</li>
    </ul> -->
    <br>
    <!--    <h5> To Get Started: </h5>
    <p>
    You are given a triangle formed by points O, A and B.
    The vertices O, A, and B of the triangle can be dragged and moved around to change
    the type (i.e., acute, obtuse or right) and size of the triangle.
    You will be able to see that the three interior angles &alpha;, &beta; and &gamma; change as the vertices are dragged around.
    </p>
<h5> To Explore:</h5>
    <p>
 Drag points O, A and B of the triangle to change the type (i.e., acute, obtuse or right) and size of the triangle OAB.
 Then, tap on the two scissors to cut out the angles &alpha; and &beta;.
 No matter what type or size of the triangle you choose, the three interior
 angles &alpha;, &beta; and &gamma; always form a half circle when stacked adjacent to each other.
 </p> -->
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Angles',
  created: function () {
      this.$store.commit('navigation/resetState');
    // Store mutations
    let title = 'Features of a Triangle';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Medians', img:'/assets/number-1.svg', action: () => this.goto('median')},
      {title: 'Centroid', img:'/assets/number-2.svg', action: () => this.goto('centroid')},
      {title: 'Altitude', img:'/assets/number-3.svg', action: () => this.goto('altitude')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('playgraph')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
    Boxes.box2();
    Boxes.box3();
  }
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
