const Boxes = {
  box1: function () {
    JXG.Options.board.minimizeReflow = 'none';
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-8, 8, 8, -8],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
        brd1.suspendUpdate();
        //title
        var resize1 = function () {
        brd1.resizeContainer(brd1.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
        brd1.fullUpdate();
        };
        window.addEventListener("resize", resize1);
      //
brd1.suspendUpdate();
brd1.create('text', [0., 7, '<b> Median of a Triangle <b>'],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/500.)}},);

//Order
//brd1.options.layer['imcrane'] =1;
//brd1.options.layer['seesaw'] =2;
//Variables
var Pt1 = brd1.create('point', [0,0],{visible: false, name:'A', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:true, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Pt2 = brd1.create('point', [4, 0],{visible: false, name:'B', size:2, strokeColor:'black', snapToGrid:true,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Pt3 = brd1.create('point', [3, 4],{visible: false, name:' ', size:2,  strokeColor:'black', snapToGrid:true,label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var Ctext = brd1.create('text', [function(){return Pt3.X() },function(){return Pt3.Y()+0.2} , 'C'],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}});

//Sides of the triangle
var AB = brd1.create('segment', [Pt1, Pt2], {strokeColor:'blue', strokeWidth:1, fixed:true});
var AC = brd1.create('segment', [Pt1, Pt3], {strokeColor:'blue', strokeWidth:1, fixed:true});
var CB = brd1.create('segment', [Pt2, Pt3], {strokeColor:'blue', strokeWidth:1, fixed:true});
brd1.create('polygon', [Pt1, Pt2, Pt3],{fixed:true, strokeWidth:2, fillColor:'white', fillOpacity:1, borders:{fixed:true, strokeColor:'blue'},visible:true});

//Midpoints
var m1 = brd1.create('midpoint', [Pt1, Pt2], {visible: false, name:'M', size:2, strokeColor:'black', fillColor: 'grey', label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var m2 = brd1.create('midpoint', [Pt1, Pt3], {visible: false, name:'L', size:2, strokeColor:'black', fillColor: 'grey', label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var m3 = brd1.create('midpoint', [Pt2, Pt3], {visible: false, name:'K', size:2, strokeColor:'black', fillColor: 'grey', label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

//Median
var median1 = brd1.create('segment', [Pt3, m1], {visible: false, strokeColor:'blue', dash: 1, strokeWidth:1, fixed:true});
var median2 = brd1.create('segment', [Pt2, m2], {visible: false, strokeColor:'blue', dash: 1, strokeWidth:1, fixed:true});
var median3 = brd1.create('segment', [Pt1, m3], {visible: false, strokeColor:'blue', dash: 1, strokeWidth:1, fixed:true});

//var gravityCenter = brd1.create('intersection', [median1, median2, median3], {visible: false, name:'G', size:2, strokeColor:'black', fillColor: 'grey', label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
//Altitude
var PtAltitude = brd1.create('point', [function(){return Pt3.X()}, 0],{visible: false, name:'h', size:1,  strokeColor:'black', fillColor: 'black', snapToGrid:false, fixed: true, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var altitude = brd1.create('segment', [Pt3, PtAltitude], {visible: false, strokeColor:'blue', dash: 1, strokeWidth:1, fixed:true});

//Angles
var ang1 = brd1.create('angle', [Pt2, Pt1, Pt3],{radius:1, strokeColor:'red', fillColor:'red', label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var ang2 = brd1.create('angle', [Pt1, Pt3, Pt2],{radius:1, strokeColor:'green', fillColor:'green',visible:true, label: {fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var ang3 = brd1.create('angle', [Pt3, Pt2, Pt1],{radius:1, strokeColor:'blue', fillColor:'blue', visible:true, label:{fontSize:function(){return 18*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var ang = brd1.create('angle', [[50, 0], Pt1, Pt2],{radius:1, strokeColor:'green', fillColor:'green',visible:false});


//Pt3.on('down', function(){i=0; j=0; return;});
//Pt2.on('down', function(){i=0; j=0;  return;});
//Pt1.on('down', function(){i=0; j=0;  return;});

//Texts
var median1Txt = brd1.create('text', [-3.5,5, function(){return 'CM = Median 1 = ' + median1.L().toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
var median2Txt = brd1.create('text', [-3.5,4, function(){return 'BL = Median 2 = ' + median2.L().toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
var median3Txt = brd1.create('text', [-3.5,3, function(){return 'AK = Median 3 = ' + median3.L().toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
//var gravityCenterTxt = brd1.create('text', [-3.7,2, function(){return 'Centroid = [ ' + gravityCenter.X().toFixed(1) + ' , ' + gravityCenter.Y().toFixed(1) + ' ]'}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});

var altitudeTxt = brd1.create('text', [-3, 4, 'hC = Altitude'],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});

//REDO AND UNDO
var redo = brd1.create('image', ['/assets/undo.svg', [1.25, -2.5], [1.5, 1.5]], {visible: true, fixed: true});
var undo = brd1.create('image', ['/assets/redo.svg', [-2.75, -2.5], [1.5, 1.5]], {visible: true, fixed: true});

undo.setLabel('Previous')
undo.label.setAttribute({visible:false, offset:[0, -25], CssStyle:'fontFamily:Oswald', fontSize:function(){return 20*brd1.canvasHeight/800}});
undo.on('over', function () {this.label.setAttribute({visible:true});});
undo.on('out', function () {this.label.setAttribute({visible:false});});

redo.setLabel('Next')
redo.label.setAttribute({visible:false, offset:[0, -25], CssStyle:'fontFamily:Oswald', fontSize:function(){return 20*brd1.canvasHeight/800}});
redo.on('over', function () {this.label.setAttribute({visible:true});});
redo.on('out', function () {this.label.setAttribute({visible:false});});

var i =0;
var maxSteps = 4;

function updateStepTexts(){
    eraseText();

    if (i<=0){
        Pt3.moveTo([3,4],10);
        Pt2.moveTo([4,0],10);
        Pt1.moveTo([0,0],10);
    }
    if(i==1){
        Pt3.setAttribute({visible: true});
        Ctext.setAttribute({visible: true});
        median1.setAttribute({visible: true});
        m1.setAttribute({visible: true});
        median1Txt.setAttribute({visible: true});
    }
    if(i==2){
        Pt2.setAttribute({visible: true});
        median2.setAttribute({visible: true});
        m2.setAttribute({visible: true});
        median2Txt.setAttribute({visible: true});
    }
    if(i==3){
        Pt1.setAttribute({visible: true});
        median3.setAttribute({visible: true});
        m3.setAttribute({visible: true});
        median3Txt.setAttribute({visible: true});
    }
    if(i==4){
        median1.setAttribute({visible: true});
        median2.setAttribute({visible: true});
        median3.setAttribute({visible: true});
        m1.setAttribute({visible: true});
        m2.setAttribute({visible: true});
        m3.setAttribute({visible: true});
        median1Txt.setAttribute({visible: true});
        median2Txt.setAttribute({visible: true});
        median3Txt.setAttribute({visible: true});
        Pt3.setAttribute({visible: true});
        Pt2.setAttribute({visible: true});
        Pt1.setAttribute({visible: true});
        Ctext.setAttribute({visible: true});
        //gravityCenter.setAttribute({visible: true});
        //gravityCenterTxt.setAttribute({visible: true});
    }
}

function eraseText(){
    Ctext.setAttribute({visible: false});
    Pt3.setAttribute({visible: false});
    Pt2.setAttribute({visible: false});
    Pt1.setAttribute({visible: false});
    median1.setAttribute({visible: false});
    median2.setAttribute({visible: false});
    median3.setAttribute({visible: false});
    m1.setAttribute({visible: false});
    m2.setAttribute({visible: false});
    m3.setAttribute({visible: false});
    median1Txt.setAttribute({visible: false});
    median2Txt.setAttribute({visible: false});
    median3Txt.setAttribute({visible: false});
    //gravityCenter.setAttribute({visible: false});
    //gravityCenterTxt.setAttribute({visible: false});
}

redo.on('down',function(){
    i = i+1;
    if (i > maxSteps){
        i = maxSteps;
    }
    updateStepTexts();
});

undo.on('down',function(){
    i=i-1;
    if (i < 0){
        i = 0;
    }
    eraseText();
    updateStepTexts();
});


brd1.unsuspendUpdate();

},
box2: function () {
  var brd2 = JXG.JSXGraph.initBoard('jxgbox2',{boundingbox: [-4, 8, 12, -8],
      keepaspectratio: true, axis:true, ticks:{visible:false},
      grid:true, showCopyright:false, showNavigation:false,
      pan:{enabled:false}, zoom:{enabled:false}});

  brd2.suspendUpdate();

  brd2.options.layer['image'] =14;
  brd2.create('text', [4, 7, '<b> Centroid <b>'],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd2.canvasWidth/500.)}},);
  var subtitle1 = brd2.create('text', [-2, 6, 'Find the centroid with the intersection of the 3 medians'],{visible: false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}},);
  var subtitle2 = brd2.create('text', [-1.5, 6, 'Find the centroid using coordinates of the vertices'],{visible: false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}},);

  //Variables
  var Pt1Centroid = brd2.create('point', [0,0],{visible: false, name: 'A', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:true, label: {offset: [-15 , 25], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var Pt2Centroid = brd2.create('point', [5, 1],{visible: false, name:'B', size:2, strokeColor:'black', snapToGrid:true,label: {offset: [15, 25], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var Pt3Centroid = brd2.create('point', [3, 4],{visible: false, name:'C', size:2,  strokeColor:'black', snapToGrid:true,label: {offset: [0, 30], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  //Coordinates
  var Atext = brd2.create('text', [function(){return Pt1Centroid.X() -0.8 },function(){return Pt1Centroid.Y()+0.15} , function(){return "[" +  Pt1Centroid.X() + ','+  Pt1Centroid.Y() + "]"}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(11*brd2.canvasWidth/500.)}});
  var Btext = brd2.create('text', [function(){return Pt2Centroid.X() + 0.4  },function(){return Pt2Centroid.Y()+ 0.15} , function(){return "[" +  Pt2Centroid.X() + ','+  Pt2Centroid.Y() + "]"}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(11*brd2.canvasWidth/500.)}});
  var Ctext = brd2.create('text', [function(){return Pt3Centroid.X() +0.25 },function(){return Pt3Centroid.Y()+0.25} , function(){return "[" +  Pt3Centroid.X() + ','+  Pt3Centroid.Y() + "]"}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(11*brd2.canvasWidth/500.)}});

  //Sides of the triangle
  var ABcentroid = brd2.create('segment', [Pt1Centroid, Pt2Centroid], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var ACcentroid = brd2.create('segment', [Pt1Centroid, Pt3Centroid], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var CBcentroid = brd2.create('segment', [Pt2Centroid, Pt3Centroid], {strokeColor:'blue', strokeWidth:1, fixed:true});
  brd2.create('polygon', [Pt1Centroid, Pt2Centroid, Pt3Centroid],{fixed:true, strokeWidth:2, fillColor:'white', fillOpacity:1, borders:{fixed:true, strokeColor:'blue'},visible:true});

  //Midpoints
  var m1centroid = brd2.create('midpoint', [Pt1Centroid, Pt2Centroid], {visible: false, name:'M', size:2, strokeColor:'black', fillColor: 'grey', label: {fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var m2centroid = brd2.create('midpoint', [Pt1Centroid, Pt3Centroid], {visible: false, name:'L', size:2, strokeColor:'black', fillColor: 'grey', label: {fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var m3centroid = brd2.create('midpoint', [Pt2Centroid, Pt3Centroid], {visible: false, name:'K', size:2, strokeColor:'black', fillColor: 'grey', label: {fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  //Median
  var median1cent = brd2.create('segment', [Pt3Centroid, m1centroid], {visible: false, strokeColor:'blue', dash: 1, strokeWidth:1, fixed:true});
  var median2cent = brd2.create('segment', [Pt2Centroid, m2centroid], {visible: false, strokeColor:'blue', dash: 1, strokeWidth:1, fixed:true});
  var median3cent = brd2.create('segment', [Pt1Centroid, m3centroid], {visible: false, strokeColor:'blue', dash: 1, strokeWidth:1, fixed:true});

  //Centroid
  var gravityCenterCentr = brd2.create('intersection', [median1cent, median2cent, median3cent], {visible: false, name:'G', size:3, strokeColor:'black', fillColor: 'grey', label: {fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var gravityCenterCentrX = brd2.create('point', [function(){return gravityCenterCentr.X()},0],{visible: true, name: '', size:1, strokeColor:'black', fillColor: 'black', snapToGrid:true, label: {offset: [-15 , 25], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var gravityCenterCentrY = brd2.create('point', [0,function(){return gravityCenterCentr.Y()}],{visible: true, name: '', size:1, strokeColor:'black', fillColor: 'black', snapToGrid:true, label: {offset: [-15 , 25], fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var gravX = brd2.create('text', [function(){return gravityCenterCentrX.X() -0.1 }, -0.15 , function(){return  gravityCenterCentrX.X().toFixed(1)}],{visible: true, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(8*brd2.canvasWidth/500.)}});
  var gravY = brd2.create('text', [-0.3, function(){return gravityCenterCentrY.Y() } , function(){return  gravityCenterCentrY.Y().toFixed(1)}],{visible: true, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(8*brd2.canvasWidth/500.)}});

  //var gravLine = brd2.create('segment', [gravityCenterCentr,gravityCenterCentrX], {visible: true, strokeColor:'blue', dash: 1, strokeWidth:1, fixed:true});
  //var gravLine2 = brd2.create('segment', [gravityCenterCentr,gravityCenterCentrY], {visible: true, strokeColor:'blue', dash: 1, strokeWidth:1, fixed:true});



  //Angles
  var ang1cent = brd2.create('angle', [Pt2Centroid, Pt1Centroid, Pt3Centroid],{radius:1, strokeColor:'red', fillColor:'red', label: {fontSize:function(){return 0*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var ang2cent = brd2.create('angle', [Pt1Centroid, Pt3Centroid, Pt2Centroid],{radius:1, strokeColor:'green', fillColor:'green',visible:true, label: {fontSize:function(){return 0*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var ang3cent = brd2.create('angle', [Pt3Centroid, Pt2Centroid, Pt1Centroid],{radius:1, strokeColor:'blue', fillColor:'blue', visible:true, label:{fontSize:function(){return 0*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  //Texts
  //var median1centTxt = brd2.create('text', [-3.5,5, function(){return 'CM = Median 1 = ' + median1cent.L().toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  //var median2centTxt = brd2.create('text', [-3.5,4, function(){return 'BL = Median 2 = ' + median2cent.L().toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  //var median3centTxt = brd2.create('text', [-3.5,3, function(){return 'AK = Median 3 = ' + median3cent.L().toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});

  var gravityCenterCentrTxt = brd2.create('text', [-3.7,1, function(){return 'Centroid = [ ' + gravityCenterCentr.X().toFixed(1) + ' , ' + gravityCenterCentr.Y().toFixed(1) + ' ]'}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});

  var Gxtxt1 = brd2.create('text', [-3.7,4,  'Gx = <u> [ x1 + x2 + x3 </u> ]'],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var Gxtxt2 = brd2.create('text', [-2.2,3.5,  '3'],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});

  var Gytxt1 = brd2.create('text', [-3.7,2.5,  'Gy = <u> [ y1 + y2 + y3 </u> ]'],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var Gytxt2 = brd2.create('text', [-2.2,2,  '3'],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});

  /*
  var CMTxt = brd2.create('text', [-3.5,5, function(){return 'GC = 2*GM' }],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var CM2Txt = brd2.create('text', [-3.5,4, function(){return 'GM = ' + (median1cent.L()/3).toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var CM3Txt = brd2.create('text', [-3.5,3, function(){return 'GC = ' + (median1cent.L()*(2/3)).toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var median11centTxt = brd2.create('text', [-3.5,2, function(){return 'CM = Median 1 = ' + median1cent.L().toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});

  var BLTxt = brd2.create('text', [-3.5,5, function(){return 'GB = 2*GL' }],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var BL2Txt = brd2.create('text', [-3.5,4, function(){return 'GL = ' + (median2cent.L()/3).toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var BL3Txt = brd2.create('text', [-3.5,3, function(){return 'GB = ' + (median2cent.L()*(2/3)).toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var median22centTxt = brd2.create('text', [-3.5,2, function(){return 'BL = Median 2 = ' + median2cent.L().toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});

  var AKTxt = brd2.create('text', [-3.5,5, function(){return 'GA = 2*GK' }],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var AK2Txt = brd2.create('text', [-3.5,4, function(){return 'GK = ' + (median3cent.L()/3).toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var AK3Txt = brd2.create('text', [-3.5,3, function(){return 'GA = ' + (median3cent.L()*(2/3)).toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var median33centTxt = brd2.create('text', [-3.5,2, function(){return 'BL = Median 2 = ' + median3cent.L().toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  */


  var CMTxt = brd2.create('text', [-3.5 , 5, '<u> GC </u>  = <u>2</u> _* CM' ],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var CM2Txt = brd2.create('text', [-3.5 , 4.6,  'GM  &nbsp; 3' ],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});

  var BLTxt = brd2.create('text', [-3.5 , 4, '<u> GB </u>  =  &nbsp;<u>2</u> _* BL' ],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var BL2Txt = brd2.create('text', [-3.5 , 3.6,  'GL  &nbsp; &nbsp; 3' ],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});

  var AKTxt = brd2.create('text', [-3.5 , 3, '<u> GA </u>  = &nbsp; <u>2</u> _* AK' ],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});
  var AK2Txt = brd2.create('text', [-3.5  ,2.6,  'GK  &nbsp; &nbsp; 3' ],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/500.)}});


  //redo5 AND undo5
  var redo5 = brd2.create('image', ['/assets/redo.svg', [1.25, -2.5], [1.5, 1.5]], {visible: true, fixed: true});
  var undo5 = brd2.create('image', ['/assets/undo.svg', [5.25, -2.5], [1.5, 1.5]], {visible: true, fixed: true});
  undo5.setLabel('Previous')
  undo5.label.setAttribute({visible:false, offset:[0, -25], CssStyle:'fontFamily:Oswald', fontSize:function(){return 20*brd2.canvasHeight/800}});
  undo5.on('over', function () {this.label.setAttribute({visible:true});});
  undo5.on('out', function () {this.label.setAttribute({visible:false});});

  redo5.setLabel('Next')
  redo5.label.setAttribute({visible:false, offset:[0, -25], CssStyle:'fontFamily:Oswald', fontSize:function(){return 20*brd2.canvasHeight/800}});
  redo5.on('over', function () {this.label.setAttribute({visible:true});});
  redo5.on('out', function () {this.label.setAttribute({visible:false});});

  var l =0;
  var maxSteps5 = 2;

  function updateStepTexts5(){
      eraseText5();

      if (l<=0){
          Pt3Centroid.moveTo([3,4],10);
          Pt2Centroid.moveTo([5,1],10);
          Pt1Centroid.moveTo([0,0],10);
      }
      if(l==1){
          median1cent.setAttribute({visible: true});
          median2cent.setAttribute({visible: true});
          median3cent.setAttribute({visible: true});
          m1centroid.setAttribute({visible: true});
          m2centroid.setAttribute({visible: true});
          m3centroid.setAttribute({visible: true});
          //median1centTxt.setAttribute({visible: true});
          //median2centTxt.setAttribute({visible: true});
          //median3centTxt.setAttribute({visible: true});
          Pt3Centroid.setAttribute({visible: true});
          Pt2Centroid.setAttribute({visible: true});
          Pt1Centroid.setAttribute({visible: true});
          Ctext.setAttribute({visible: true});
          Btext.setAttribute({visible: true});
          Atext.setAttribute({visible: true});
          gravityCenterCentr.setAttribute({visible: true});
          gravityCenterCentrTxt.setAttribute({visible: true});
          subtitle1.setAttribute({visible: true});

          CMTxt.setAttribute({visible: true});
          CM2Txt.setAttribute({visible: true});
          BLTxt.setAttribute({visible: true});
          BL2Txt.setAttribute({visible: true});
          AKTxt.setAttribute({visible: true});
          AK2Txt.setAttribute({visible: true});


      }

      if(l==2){
          median1cent.setAttribute({visible: true});
          median2cent.setAttribute({visible: true});
          median3cent.setAttribute({visible: true});
          m1centroid.setAttribute({visible: true});
          m2centroid.setAttribute({visible: true});
          m3centroid.setAttribute({visible: true});
          //median1centTxt.setAttribute({visible: true});
          //median2centTxt.setAttribute({visible: true});
          //median3centTxt.setAttribute({visible: true});
          Pt3Centroid.setAttribute({visible: true});
          Pt2Centroid.setAttribute({visible: true});
          Pt1Centroid.setAttribute({visible: true});
          Ctext.setAttribute({visible: true});
          Btext.setAttribute({visible: true});
          Atext.setAttribute({visible: true});
          gravityCenterCentr.setAttribute({visible: true});
          gravityCenterCentrTxt.setAttribute({visible: true});
          Gxtxt1.setAttribute({visible: true});
          Gxtxt2.setAttribute({visible: true});
          Gytxt1.setAttribute({visible: true});
          Gytxt2.setAttribute({visible: true});
          subtitle2.setAttribute({visible: true});

      }

  }

  function eraseText5(){
      Ctext.setAttribute({visible: false});
      Btext.setAttribute({visible: false});
      Atext.setAttribute({visible: false});
      subtitle1.setAttribute({visible: false});
      subtitle2.setAttribute({visible: false});
      Pt3Centroid.setAttribute({visible: false});
      Pt2Centroid.setAttribute({visible: false});
      Pt1Centroid.setAttribute({visible: false});
      median1cent.setAttribute({visible: false});
      median2cent.setAttribute({visible: false});
      median3cent.setAttribute({visible: false});
      m1centroid.setAttribute({visible: false});
      m2centroid.setAttribute({visible: false});
      m3centroid.setAttribute({visible: false});
      //median1centTxt.setAttribute({visible: false});
      //median2centTxt.setAttribute({visible: false});
      //median3centTxt.setAttribute({visible: false});
      gravityCenterCentr.setAttribute({visible: false});
      gravityCenterCentrTxt.setAttribute({visible: false});


      CMTxt.setAttribute({visible: false});
      CM2Txt.setAttribute({visible: false});
      BLTxt.setAttribute({visible: false});
      BL2Txt.setAttribute({visible: false});
      AKTxt.setAttribute({visible: false});
      AK2Txt.setAttribute({visible: false});

      /*
      median11centTxt.setAttribute({visible: false});
      BLTxt.setAttribute({visible: false});
      BL2Txt.setAttribute({visible: false});
      BL3Txt.setAttribute({visible: false});
      median22centTxt.setAttribute({visible: false});
      AKTxt.setAttribute({visible: false});
      AK2Txt.setAttribute({visible: false});
      AK3Txt.setAttribute({visible: false});
      median33centTxt.setAttribute({visible: false});
      */
      Gxtxt1.setAttribute({visible: false});
      Gxtxt2.setAttribute({visible: false});
      Gytxt1.setAttribute({visible: false});
      Gytxt2.setAttribute({visible: false});

  }

  redo5.on('down',function(){
      l = l+1;
      if (l > maxSteps5){
          l = maxSteps5;
      }
      updateStepTexts5();
  });

  undo5.on('down',function(){
      l=l-1;
      if (l < 0){
          l = 0;
      }
      eraseText5();
      updateStepTexts5();
  });

  brd2.unsuspendUpdate();
},
box3:function(){
  var brd3 = JXG.JSXGraph.initBoard('jxgbox3',{boundingbox: [-4, 8, 12, -8],
      keepaspectratio: true, axis:true, ticks:{visible:false},
      grid:true, showCopyright:false, showNavigation:false,
      pan:{ensideABled:false}, zoom:{ensideABled:false}});

  brd3.suspendUpdate();

  brd3.options.layer['image'] =14;
  brd3.create('text', [4, 7, '<b> Altitude of a Triangle <b>'],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd3.canvasWidth/500.)}},);

  //Order
  //brd3.options.layer['imcrane'] =1;
  //brd3.options.layer['seesaw'] =2;

  //Variables
  //var i=0; var j=0;
  var point1 = brd3.create('point', [0,0],{visible: false, name:'A', size:2, strokeColor:'black', fillColor: 'red', snapToGrid:true, label: {fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var point2 = brd3.create('point', [4, 0],{visible: false, name:'B', size:2, strokeColor:'black', snapToGrid:true,label: {fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var point3 = brd3.create('point', [3, 4],{visible: false, name:' ', size:2,  strokeColor:'black', snapToGrid:true,label: {fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var C1text = brd3.create('text', [function(){return point3.X() },function(){return point3.Y()+0.2} , 'C'],{visible: true, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd3.canvasWidth/500.)}});

  //Sides of the triangle
  var sideAB = brd3.create('segment', [point1, point2], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var sideAC = brd3.create('segment', [point1, point3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  var sideCB = brd3.create('segment', [point2, point3], {strokeColor:'blue', strokeWidth:1, fixed:true});
  brd3.create('polygon', [point1, point2, point3],{fixed:true, strokeWidth:2, fillColor:'white', fillOpsideACity:1, borders:{fixed:true, strokeColor:'blue'},visible:true});


  //Sides extended
  var lisideAB = brd3.create('line', [point1, point2], {visible: false, dash: 1, strokeColor:'black', strokeWidth:1, fixed:true});
  var lisideAC = brd3.create('line', [point1, point3], {visible: false, dash: 1, strokeColor:'black', strokeWidth:1, fixed:true});
  var lisideCB = brd3.create('line', [point2, point3], {visible: false, dash: 1, strokeColor:'black', strokeWidth:1, fixed:true});


  //Parallel sides
  //var pasideAB = brd3.create('parallel', [lisideAB, point3], {dash: 1, strokeColor:'black', strokeWidth:1, fixed:true});
  //var pasideAC = brd3.create('parallel', [lisideAC, point2], {dash: 1, strokeColor:'black', strokeWidth:1, fixed:true});
  //var psideAsideCB = brd3.create('parallel', [lisideCB, point1], {dash: 1, strokeColor:'black', strokeWidth:1, fixed:true});


  //Altitudes

  var perp1 = brd3.create('perpendicularsegment', [point3, lisideAB] , {dash: 1, strokeColor:'blue', strokeWidth:1, fixed:true, visible: false});
  var perp2 = brd3.create('perpendicularsegment', [point2, lisideAC] , {dash: 1,strokeColor:'blue', strokeWidth:1, fixed:true, visible: false});
  var perp3 = brd3.create('perpendicularsegment', [point1, lisideCB] , {dash: 1,strokeColor:'blue', strokeWidth:1, fixed:true, visible: false});


  //Intersections
  var inter1 = brd3.create('intersection', [sideAB, perp1], {visible: false, name:'M', size:2, strokeColor:'black', fillColor: 'grey', label: {fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var inter2 = brd3.create('intersection', [sideAC, perp2], {visible: false, name:'L', size:2, strokeColor:'black', fillColor: 'grey', label: {fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var inter3 = brd3.create('intersection', [sideCB, perp3], {visible: false, name:'K', size:2, strokeColor:'black', fillColor: 'grey', label: {fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});


  //var perp22 = brd3.create('perpendicularsegment', [point1, perp2] , {dash: 0,strokeColor:'blue', strokeWidth:1, fixed:true, visible: true});
  //var perp33 = brd3.create('perpendicularsegment', [point2, perp3] , {dash: 0,strokeColor:'blue', strokeWidth:1, fixed:true, visible: true});


  //Angles
  var angle1 = brd3.create('angle', [point2, point1, point3],{radius:1, strokeColor:'red', fillColor:'red', label: {fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var angle2 = brd3.create('angle', [point1, point3, point2],{radius:1, strokeColor:'green', fillColor:'green',visible:true, label: {fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
  var angle3 = brd3.create('angle', [point3, point2, point1],{radius:1, strokeColor:'blue', fillColor:'blue', visible:true, label:{fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

  var angle = brd3.create('angle', [[50, 0], point1, point2],{radius:1, strokeColor:'green', fillColor:'green',visible:false});


  //point3.on('down', function(){i=0; j=0; return;});
  //point2.on('down', function(){i=0; j=0;  return;});
  //point1.on('down', function(){i=0; j=0;  return;});

  //Texts
  var perp1Txt = brd3.create('text', [-3.5,5, function(){return 'CM = Altitude 1 = ' + perp1.L().toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd3.canvasWidth/500.)}});
  var perp2Txt = brd3.create('text', [-3.5,4, function(){return 'BL = Altitude 2 = ' + perp2.L().toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd3.canvasWidth/500.)}});
  var perp3Txt = brd3.create('text', [-3.5,3, function(){return 'AK = Altitude 3 = ' + perp3.L().toFixed(2)}],{visible: false, fixed: true, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd3.canvasWidth/500.)}});

  //redo1 AND undo1
  var redo1 = brd3.create('image', ['/assets/redo.svg', [1.25, -4], [1.5, 1.5]], {visible: true, fixed: true});
  var undo1 = brd3.create('image', ['/assets/undo.svg', [5.25, -4], [1.5, 1.5]], {visible: true, fixed: true});

  undo1.setLabel('Previous')
  undo1.label.setAttribute({visible:false, offset:[0, -25], CssStyle:'fontFamily:Oswald', fontSize:function(){return 20*brd3.canvasHeight/800}});
  undo1.on('over', function () {this.label.setAttribute({visible:true});});
  undo1.on('out', function () {this.label.setAttribute({visible:false});});

  redo1.setLabel('Next')
  redo1.label.setAttribute({visible:false, offset:[0, -25], CssStyle:'fontFamily:Oswald', fontSize:function(){return 20*brd3.canvasHeight/800}});
  redo1.on('over', function () {this.label.setAttribute({visible:true});});
  redo1.on('out', function () {this.label.setAttribute({visible:false});});

  var j = 0;
  var maxSteps1 = 4;

  function updateStepTexts1(){
      eraseText1();

      if (j<=0){
          point3.moveTo([3,4],10);
          point2.moveTo([4,0],10);
          point1.moveTo([0,0],10);
      }
      if(j==1){
          point3.setAttribute({visible: true});
          C1text.setAttribute({visible: true});

          perp1.setAttribute({visible: true});
          perp1Txt.setAttribute({visible: true});
          inter1.setAttribute({visible: true});
      }
      if(j==2){
          point2.setAttribute({visible: true});
          perp2.setAttribute({visible: true});
          //perp22.setAttribute({visible: true});
          inter2.setAttribute({visible: true});


          perp2Txt.setAttribute({visible: true});
      }
      if(j==3){
          point1.setAttribute({visible: true});
          perp3.setAttribute({visible: true});
          //perp33.setAttribute({visible: true});
          perp3Txt.setAttribute({visible: true});
          inter3.setAttribute({visible: true});

      }
      if(j==4){
          perp1.setAttribute({visible: true});
          perp2.setAttribute({visible: true});
          perp3.setAttribute({visible: true});
          //perp22.setAttribute({visible: true});
          //perp33.setAttribute({visible: true});

          perp1Txt.setAttribute({visible: true});
          perp2Txt.setAttribute({visible: true});
          perp3Txt.setAttribute({visible: true});
          point3.setAttribute({visible: true});
          point2.setAttribute({visible: true});
          point1.setAttribute({visible: true});

          inter1.setAttribute({visible: true});
          inter2.setAttribute({visible: true});
          inter3.setAttribute({visible: true});

      }
  }

  function eraseText1(){

      point3.setAttribute({visible: false});
      point2.setAttribute({visible: false});
      point1.setAttribute({visible: false});

      perp1.setAttribute({visible: false});
      perp2.setAttribute({visible: false});
      perp3.setAttribute({visible: false});
      //perp22.setAttribute({visible: false});
      //perp33.setAttribute({visible: false});

      perp1Txt.setAttribute({visible: false});
      perp2Txt.setAttribute({visible: false});
      perp3Txt.setAttribute({visible: false});

      inter1.setAttribute({visible: false});
      inter2.setAttribute({visible: false});
      inter3.setAttribute({visible: false});

  }

  redo1.on('down',function(){
      j = j+1;
      if (j > maxSteps1){
          j = maxSteps1;
      }
      updateStepTexts1();
  });

  undo1.on('down',function(){
      j=j-1;
      if (j < 0){
          j = 0;
      }
      eraseText1();
      updateStepTexts1();
  });

  brd3.unsuspendUpdate();

}
}
export default Boxes;
